import ApiDocument from 'Services/api/controller/document';
import BaseBox from './baseBox';
import { DOUBLE_SIDE_TAB_INDEX } from 'Utils/boxTypes';
import { DATE_FORMAT_FOR_BACKEND, formatTime } from 'Services/time/formatTime';
import { filterSortTypes } from 'Hooks/useFilter';

const boxFunctions = {
  columns: [
    {
      label: 'Checkbox',
      width: 0.02,
      dataKey: 'checkbox',
      hasFilter: false
    },
    {
      label: 'status',
      width: 0.07,
      dataKey: 'state',
      hasFilter: true
    },
    {
      label: 'senderName',
      width: 0.26,
      dataKey: 'tin',
      hasFilter: true
    },
    {
      label: 'documentType',
      width: 0.08,
      dataKey: 'typeName',
      hasFilter: true
    },
    ...(true ? [{
      label: 'document',
      width: 0.07,
      dataKey: 'dateAndNumber',
      hasFilter: true
    }] : []),
    ...(true ? [{
      label: 'contract',
      width: 0.07,
      dataKey: 'contract',
      hasFilter: true
    }] : []),
    ...(true ? [{
      label: 'branchName',
      width: 0.1,
      dataKey: 'branchName',
      hasFilter: true
    }] : []),
    ...(true ? [{
      label: 'filialNum',
      width: 0.1,
      dataKey: 'filialNum',
      hasFilter: true
    }] : []),
    ...(true ? [{
      label: 'constructionObject',
      width: 0.06,
      dataKey: 'constructionObject',
      hasFilter: true
    }] : []),
    ...(true ? [{
      label: 'ageInDays',
      width: 0.06,
      dataKey: 'ageInDays',
      hasFilter: true
    }] : []),
    ...(true ? [{
      label: 'sum',
      width: 0.11,
      dataKey: 'sum',
      hasFilter: true
    }] : []),
  ],

  getList(body) {
    return ApiDocument.getDocumentList(body);
  },

  getColumns() {
    return this.columns;
  },
  getAllowedActionKeys() {
    return Object.keys(this.actions).filter((_actionKey) => _actionKey !== 'delegate' && _actionKey !== 'reply');
  },
  reject(values) {
    return ApiDocument.rejectDocument(values);
  },
  notApproved(values) {
    return ApiDocument.notApprovedDocument(values)
  },
  cancel(values) {
    return ApiDocument.cancelDocument(values);
  },
  generateBody(state, isFetch) {
    let body = {};
    for (let [key, value] of Object.entries(state.checkedBoxes || {})) {
      if (key === state?.selectedProperty && !isFetch) continue;
      key = key !== 'typeName' ? key : 'type';
      key = key !== 'tin2' ? key : 'tin';
      key = key !== 'name' ? key : 'partnerNames';
      key = key !== 'mainSignerName' ? key : 'mainSignersTin';

      if (!state.withoutObject) {
        key = key !== 'constructionObject' ? key : 'constructionObjects';
      } else {
        body.constructionObjects = [0];
      }
      body = { ...body, [key]: value };
    }

    // if (state.date?.dateAndNumber) {
    //   body = {
    //     dateFrom: formatTime(state.date.dateAndNumber.dateFrom, DATE_FORMAT_FOR_BACKEND),
    //     dateTo: formatTime(state.date.dateAndNumber.dateTo, DATE_FORMAT_FOR_BACKEND)
    //   };
    // }
    body.dateFrom = formatTime(state?.date?.dateFrom, DATE_FORMAT_FOR_BACKEND);
    body.dateTo = formatTime(state?.date?.dateTo, DATE_FORMAT_FOR_BACKEND);
    body[state.number?.function || 'numberStartWith'] = state.number?.number;
    body.id = state.number?.id;

    body.fromSum = state.sum?.inputFrom;
    body.toSum = state.sum?.inputTo;
    body.currentFilialNum = state.filialNum;
    body.ageInDaysFrom = state?.ageInDaysFrom;
    body.ageInDaysTo = state?.ageInDaysTo;
    body.contractDate = formatTime(state?.contract?.contractDate, DATE_FORMAT_FOR_BACKEND);
    body.contractNumber = state?.contract?.contractNumber;
    body.currentBranchId = Array.isArray(state?.branchName?.currentBranchId)
      ? state.branchName.currentBranchId.map((branch) => branch.value.id)
      : [];
    body.currentEmployeeId = Array.isArray(state?.branchName?.currentEmployeeId)
      ? state.branchName.currentEmployeeId.map((employee) => employee.value.id)
      : [];
    body.blocked = state.blocked;
    body.keyword = state.inputNameTin;

    // NOTE - sortBy keyini backend uchun moslab olish
    let [key, type] = [];
    if(Object.keys(state.activeSortProperty).length) {
      try {
        [key, type] = Object.entries(state.activeSortProperty)[0];
      } catch (err) {
        console.log('SORT: ', err);
      }
    }
    switch (key) {
      case 'tin': {
        body.sort = 'senderName';
        body.dir = type;
        break;
      }
      case 'dateAndNumber': {
        type = type === 'initial' ? filterSortTypes.descend : type;
        body.sort = 'docDate';
        body.dir = type;
        break;
      }
      case 'contract': {
        body.sort = 'contractDate';
        body.dir = type;
        break;
      }
      case 'sum': {
        body.sort = 'summa';
        body.dir = type;
        break;
      }
      case 'ageInDays': {
        body.sort = 'ageInDays';
        body.dir = type === filterSortTypes.ascend ? filterSortTypes.descend : filterSortTypes.ascend;
        break;
      }
      default: {
        // body.sort = 'docDate';
        // body.dir = 'desc';
        break;
      }
    }

    return body;
  },
  defaultGetList(body) {
    return ApiDocument.getDocumentList(body);
  }
};

class IncomeBoxModule extends BaseBox {
  moduleType = 'BOX';
  commonType = 'BOX';
  tabIndex = DOUBLE_SIDE_TAB_INDEX;
  routeType = 10;
}

Object.assign(IncomeBoxModule.prototype, boxFunctions);

const incomeBoxModule = new IncomeBoxModule();

export { boxFunctions };

export default incomeBoxModule;
