import React, { useEffect, useState, useCallback, Suspense } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import moment from "moment";

import BaseFormItem, { BaseRadio } from 'Common/Style/styledFormItemWrapper';
import BaseInput from 'Common/Style/styledInput';
import { string, object } from 'yup';
import BaseLabel, { warningClass } from 'Common/Style/styledLabel';
import { DatePicker } from "Components/atoms/datePicker";
import { formatTime, DATE_FORMAT_FOR_BACKEND } from 'Services/time/formatTime';
import notification, { alertTypes, cAlert } from "Components/atoms/toast";
import EimzoSign, { eimzoService } from 'Services/e-imzo';
import { Login as EImzoLogin } from 'Services/e-imzo/login';
import * as _ from './formStyle'
import { isEmpty } from "Utils/isEmptyObject";
import ApiDocument from "Services/api/controller/document";
import browserStorage from "Services/browserStorage";
import AcceptConfirmationModal from "Modules/publicDocumentView/components/acceptComnfirmationModal";
import requestState from "Services/api/status";
import ApiUser from "Services/api/controller/user";
import useFetch from "Hooks/useFetch";
import FlexListForLanding from "./component/FlexListForLanding/FlexListForLanding";
import { useTranslation } from "react-i18next";
import SendToPublicForLanding from "./component/SendToPublicForLanding";


const Form = ({ fileName, filePath, children, finalCalb }) => {
    const { t } = useTranslation();
    const [keys, setKeys] = useState([]); // NOTE - keys of eimzo
    const [selectedKey, setSelectedKey] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [actionStatus, setActionStatus] = useState(requestState.initial);
    const [modal, setModal] = useState('closed');
    const [publicView, setPublicView] = useState(false);
    const [documentId, setDocumentId] = useState(null);
    const [signContent, setSignContent] = useState(null)
    const phoneRegex = /^\+\d{3} \d{2} \d{3} \d{2} \d{2}$/

    const [buyerNameState, , , getBuyerName] = useFetch();

    const validationSchema = object()?.shape({
        buyerTin: string()?.required('buyerTin'),
        documentNo: string()?.required('documentNo'),
        // documentDate: string()?.required('documentDate'),
        offerAccept: string(),

        phone: string().when('phone', {
            is: (value) => value !== undefined && value !== null && value !== '',
            then: string().matches(phoneRegex, 'Phone number is not valid'),
            otherwise: string().notRequired(),
        }),

    }, [
        ['buyerTin', 'phone'],
        ['documentNo', 'phone'],
        ['offerAccept', 'phone'],
        ['phone', 'phone']
    ]);

    const { handleSubmit, reset, control, register, watch, setValue, errors } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const watchOfferAccept = watch('offerAccept')

    useEffect(() => {
        EImzoLogin.init(2, function (key) {
            setKeys((prevKeys) => {
                if (prevKeys.map((prevKey) => prevKey.getProp('tin')).includes(key.getProp('tin'))) {
                    return prevKeys;
                }

                return [...prevKeys, key];
            });
        });
    }, []);

    useEffect(() => {
        if (fileName && filePath) {
            setValue('fileName', fileName)
            setValue('filePath', filePath)
        }
    }, [fileName, filePath])

    const setSerialNumber = (cb) => {
        EImzoLogin.init(
            4,
            async function (data, id, errorMessage) {
                if (errorMessage) {
                    setErrorMessage(errorMessage);
                } else {
                    browserStorage.set('id', id);

                    await cb();
                }
            },
            selectedKey.value
        );
    };

    const onSign = async () => {
        if (!fileName || !filePath) {
            return notification?.setType('error').setMessage('Ошибка файла').alert()
        }

        try {
            setActionStatus(requestState.loading);
            const sign = await eimzoService.createSign(filePath || signContent?.filePath);

            const { filePath: storedFilePath, fileName, ...data } = signContent;

            const response = await ApiDocument.sendPublicDoc({ ...data, sign: sign });

            if (!response.fail) {
                setPublicView(true);
                setDocumentId(response?.data?.data)
                cAlert(alertTypes.success, response.message || 'Success');
                setSignContent(null);
                browserStorage.remove('id')
                reset();
                // finalCalb();
                onCloseModal();
            } else {
                throw new Error(response.message);
            }
        } catch (e) {
            cAlert(alertTypes.error, e.message || e || 'Error');
            setActionStatus(requestState.initial);
        }
    }

    const onSubmit = handleSubmit(async (values) => {

        if (!fileName || !filePath) {
            return notification?.setType('error').setMessage('Ошибка файла').alert()
        }

        const body = {};
        for (let key in values) {
            if (key?.toLowerCase()?.includes('date')) {
                body[key] = formatTime(values[key] || new Date, DATE_FORMAT_FOR_BACKEND);
            } else {
                body[key] = values[key];
            }
        }
        body.fileName = fileName;
        body.filePath = filePath;

        setSignContent(body);
        setModal('accept');
    });

    const onKeyChange = useCallback((e) => {
        let key = keys.filter(obj => obj.getProp('tin') === e.split(' ')[0]).map((key, i) => ({ index: i, label: `${key.getProp('tin')} - ${key.getProp('cn')}`, value: key }));
        setSelectedKey(key[0]);
    }, [keys]);

    const disabledDate = (currentDay) => currentDay && (currentDay > moment().endOf('day') || currentDay < new Date('01.01.2000'));

    const onCloseModal = () => setModal('closed');

    const handleSearch = (newValue) => {
        if (newValue?.toString()?.length >= 4) {
            getBuyerName(ApiUser.getBuyerName, newValue)
        }
    };

    return (
        <>
            <Suspense fallback={<div />}>
                <AcceptConfirmationModal
                    visible={modal === 'accept'}
                    onClose={onCloseModal}
                    onConfirm={() => setSerialNumber(onSign)}
                    status={actionStatus}
                    selectedKey={selectedKey}
                    onKeyChange={onKeyChange}
                    keyOptions={keys}
                />
            </Suspense>
            <_.MainContainer>
                <_.PdfWrap>
                    {children}
                </_.PdfWrap>
                <_.FormContainer onSubmit={onSubmit}>
                    <_.Fields>
                        <_.FieldsTitle>Получатель</_.FieldsTitle>
                        <BaseFormItem>
                            <Controller
                                name={'buyerTin'}
                                control={control}
                                render={(arg) => (
                                    <FlexListForLanding
                                        placeholder={t('receiverTIN')}
                                        name={arg.name}
                                        value={arg.value}
                                        handleSearch={handleSearch}
                                        onChange={arg.onChange}
                                        data={!isEmpty(buyerNameState.data) ? buyerNameState.data : []}
                                        className={errors?.buyerTin?.type && warningClass}
                                    />
                                )}
                            />
                        </BaseFormItem>
                        <BaseFormItem>
                            <BaseInput
                                className={errors?.documentNo?.type && warningClass}
                                ref={register}
                                placeholder="Номер документа "
                                name={'documentNo'}
                            />
                        </BaseFormItem>
                        <BaseFormItem>
                            <Controller
                                control={control}
                                name={'documentDate'}
                                render={(arg) => <DatePicker
                                    {...arg}
                                    name={arg.name}
                                    placeholder={'Дата документа'}
                                    className={errors?.documentDate?.type && warningClass}
                                    selected={arg.value}
                                    disabledDate={disabledDate}
                                    defaultValue={moment(new Date, 'DD-MM-YYYY')}
                                />}
                            />
                        </BaseFormItem>
                        <BaseFormItem>
                            <Controller
                                name="phone"
                                control={control}
                                render={(arg) => (
                                    <NumberFormat
                                        {...arg}
                                        style={{ color: errors?.phone?.type ? 'red' : 'inherit' }}
                                        customInput={BaseInput}
                                        type="tel"
                                        mask="_"
                                        placeholder={'Телефон +998 95 194 24 24'}
                                        format="+### ## ### ## ##"
                                        value={arg?.value}
                                    />
                                )}
                            />
                        </BaseFormItem>

                        <BaseFormItem className="align-items-center" style={{ display: 'block' }}>
                            <BaseRadio>
                                <Controller
                                    name="offerAccept"
                                    control={control}
                                    render={(arg) => (
                                        <BaseInput
                                            className={errors?.offerAccept?.type && warningClass}
                                            id={'offerAccept'}
                                            onClick={() => setValue('offerAccept', !watchOfferAccept)}
                                            checked={!!watchOfferAccept}
                                            type="radio"
                                            name={'offerAccept'}
                                        />
                                    )}
                                />
                                <BaseLabel htmlFor="offerAccept" style={{ marginLeft: '10px' }}>
                                    <a target='_blank' href="https://24m.uz/static/media/policy.d4f1ad1f.pdf">
                                        {'Я согласен с Условиями использования.'}
                                    </a>
                                </BaseLabel>
                            </BaseRadio>
                        </BaseFormItem>

                    </_.Fields>
                    <_.Button disabled={!watchOfferAccept} type="submit" >Отправить</_.Button>
                </_.FormContainer>
            </_.MainContainer>
            <React.Suspense fallback={<div />}>
                <SendToPublicForLanding isVisible={publicView} func={finalCalb} documentId={documentId} />
            </React.Suspense>
        </>
    )
}

export default Form;