import { SoliqErrorHandler } from "./SoliqErrorUtils";

const WRONG_PASSWORD = {
  ru: 'Неверный пароль!',
  latin: 'Parol xato kiritildi!',
  cyrillic: 'Парол хато киритилди!'
};
const EIMZO_IS_NOT_LAUNCHED = {
  ru: 'Программа E-IMZO не запущена!',
  latin: 'E-IMZO dasturi ishga tushirilmagan!',
  cyrillic: 'E-IMZO дастури ишга туширилмаган!'
};
const NEED_TO_RELOGIN = {
  ru: 'Возникла проблема со E-IMZO, пожалуйста, выйдите из своей учетной записи и попробуйте войти снова!',
  latin: 'E-IMZO bilan muammo yuz berdi, iltimos, tizimdan chiqing va qaytadan kirishga harakat qiling!',
  cyrillic: 'E-IMZO билан муаммо юз берди, илтимос, тизимдан чиқинг ва қайтадан киришга ҳаракат қилинг!'
};
const FAIL_ADD_MXIK = {
  ru: 'FAIL_ADD_MXIK',
  latin: 'FAIL_ADD_MXIK',
  cyrillic: 'FAIL_ADD_MXIK'
}



export function getNormalizedErrorText(err = '') {
  const lng = localStorage.getItem('i18nextLng') || 'ru';

  try {
    switch (true) {
      case (SoliqErrorHandler.checkIsSoliqError(err)): {
        return SoliqErrorHandler.getSoliqErrorText(err);
      }
      case (err?.includes?.('BadPaddingException')): {
        return WRONG_PASSWORD[lng];
      }
      case (err === 'EIMZO_IS_NOT_LAUNCHED'): {
        return EIMZO_IS_NOT_LAUNCHED[lng];
      }
      case (err.includes('API-key для домена недействителен') || err.includes('Ключ по идентификатору не найден')): {
        return NEED_TO_RELOGIN[lng];
      }
      case (err === 'FAIL_ADD_MXIK'): {
        return FAIL_ADD_MXIK[lng];
      }
      default: {
        return err;
      }
    }

  } catch (unexpectedError) {

    return err;
  }
}



