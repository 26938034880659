import React, { useEffect, useRef } from 'react';
import logo from "../../images/BDM-logo.png";
import { Link, useLocation } from "react-router-dom";
import policy from "../../policy.pdf";


const Header = () => {
  const location = useLocation();
  const burgerMenuRef = useRef();
  const handleToggle = () => {
    if (burgerMenuRef.current.classList.contains('active')) {
      burgerMenuRef.current.classList.remove('active');
    } else {
      burgerMenuRef.current.classList.add('active');
    }
  }

  useEffect(() => {
    burgerMenuRef.current.classList.remove('active');
  }, [location]);

  return (
    <header className={'site-header'}>
      <div className={'container site-header__container'}>
        <a className={'site-header__logo-link'} href={'/'}>
          <img src={logo} alt={'Business documents management logo 24m.uz Цифровой Обмен Документами'} />
        </a>
        <ul className={'site-header__list'}>
          <li className={'site-header__item'}>
            <a className={'site-header__link'} href={'/#secondSection'}>
              Что такое BDM
            </a>
          </li>
          <li className={'site-header__item'}>
            <a className={'site-header__link'} href={'/#thirdSection'}>
              Возможности
            </a>
          </li>
          <li className={'site-header__item'}>
            <a className={'site-header__link'} href={'/#seventhSextion'}>
              Интеграция
            </a>
          </li>
          <li className={'site-header__item'}>
            <a className={'site-header__link'} href={'/#pricingSection'}>
              Тарифы
            </a>
          </li>
          <li className={'site-header__item'}>
            <a className={'site-header__link'} href={'/#form-section'}>
              Связаться
            </a>
          </li>
          <li className={'site-header__item'}>
            <Link className={'site-header__link'} to="/blogs">
              Блог
            </Link>
          </li>
        </ul>

        <ul className={'site-header__list'}>
          <li className={'site-header__item'}>
            <a href={'https://t.me/bdm_24m_admin'} className={'cta-btn-outlined site-header__link__description'} target={'_blank'}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#29b6f6" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path>
                <path
                  fill="#fff"
                  d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"></path>
                <path
                  fill="#b0bec5"
                  d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"></path>
                <path
                  fill="#cfd8dc"
                  d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"></path>
              </svg>
              <span> Консультация</span>
            </a>
          </li>
          <li className={'site-header__item'}>
            <a
              className={'cta-btn hero__cta-btn'}
              href={process.env.NODE_ENV === 'development' ? 'http://localhost:3000/login' : 'https://24m.uz/login'}
              style={{ cursor: 'pointer' }}>
              Войти
            </a>
          </li>
        </ul>
        <div className='flex-center gap-3'>
          <a
            className={'cta-btn logIn-btn'}
            href={process.env.NODE_ENV === 'development' ? 'http://localhost:3000/login' : 'https://24m.uz/login'}
            style={{ cursor: 'pointer', padding: '10px 9px' }}>
            Войти
          </a>
          <div className={'burger-toggle'} id={'burger-toggle-div'} onClick={handleToggle}>
            <span></span>
          </div>
        </div>
      </div>
      <div className={'burger-menu__bg-color'}></div>
      <div className={'burger-menu'} ref={burgerMenuRef}>
        <div className={'burger-menu__inner'}>
          <a className={'burger-menu__logo-link'} href={'/'}>
            <img src={logo} alt={'Business documents management logo 24m.uz Цифровой Обмен Документами'} />
          </a>
          <ul className={'burger-menu__nav-list'}>
            <li className={'burger-menu__nav-item'}>
              <a className="burger-menu__nav-link"
                href={process.env.NODE_ENV === 'development' ? 'http://localhost:3000/login' : 'https://24m.uz/login'}
              >
                Что такое BDM
              </a>
            </li>
            <li className={'burger-menu__nav-item'}>
              <a className="burger-menu__nav-link" href={'/#thirdSection'}>
                Возможности
              </a>
            </li>
            <li className={'burger-menu__nav-item'}>
              <a className="burger-menu__nav-link" href={'/#seventhSextion'}>
                Интеграция
              </a>
            </li>
            <li className={'burger-menu__nav-item'}>
              <a className="burger-menu__nav-link" href={'/#pricingSection'}>
                Тарифы
              </a>
            </li>
            <li className={'burger-menu__nav-item'}>
              <Link className={'site-header__link'} to="/blogs">
                Блог
              </Link>
            </li>
          </ul>


          <a className={'cta-btn burger-menu__cta-link'} href={'/#form-section'}>
            Узнать подробнее
          </a>

          <div className={'burger-menu__socials-wrapper'}>
            <h3 className={'burger-menu__social-title'}>Присоединяйтесь к нам</h3>
            <ul className={'burger-menu__social-list'}>
              <li className={'burger-menu__social-item'}>
                <a href={'https://www.linkedin.com/company/everbestlab/mycompany/'} className={'burger-menu__social-link'} target={'_blank'}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                  </svg>
                </a>
              </li>
              <li className={'burger-menu__social-item'}>
                <a href="https://www.youtube.com/@bdm24m2" className={'burger-menu__social-link'} target={'_blank'}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                  </svg>
                </a>
              </li>
              <li className={'burger-menu__social-item'}>
                <a href="https://www.instagram.com/everbestlab/" className={'burger-menu__social-link'} target={'_blank'}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div className="burger-menu__footer">
            <a href="tel:+998951942424">+998 95 194 24 24</a>
            <a href="mailto:info@24m.uz">info@24m.uz</a>
            <a href={policy} target="_blank">
              Публичная оферта
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;